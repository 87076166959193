export class S3Library {//should be called S3Repository. 
  constructor(urlPresigner, serviceWorkerAvailable = false) {
    this.urlPresigner = urlPresigner;
    this.keyToUrl = async (resourceKey, signal, overrideServiceWorker = false) => {
      if(serviceWorkerAvailable && !overrideServiceWorker)
        return "/cassette_api/"+resourceKey;
      else if(urlPresigner)
        return await urlPresigner.getPresignedFileUrl(resourceKey, signal);
      return;
    }
  }
  static LIBRARY_METADATA_KEY = "library.json";

  async getLibraryMetadata(overrideServiceWorker = false, signal = null) {
    const url = await this.keyToUrl(S3Library.LIBRARY_METADATA_KEY, null, overrideServiceWorker);
    const req = new Request(url,{signal:signal});
    return await fetch(req).then(response => response.json());
  }

  async getSongUrl(song, signal) {
    const resourceKey = `${song.recordingId}/${song.filename}`;
    const url = await this.keyToUrl(resourceKey, signal, true);
    return url;
  }

  async getResourceUrl(resourceKey, signal ) {
    const url = await this.keyToUrl(resourceKey, signal);
    return url;
  }

  async getImageUrls(recording, signal) {
    const images = [];
    for(let index in recording.images) {
      const name = recording.images[index].slice(0,recording.images[index].length-4);
      const resourceKey = `${recording.recordingId}/640_${name}.webp`;
      const resourceKey400 = `${recording.recordingId}/400_${name}.webp`;
      const url = await this.keyToUrl(resourceKey, signal);
      const url400 = await this.keyToUrl(resourceKey400, signal);

      images.push({url400:url400, url600:url, url:url, title:name});
    }
    return images;
  }
}
