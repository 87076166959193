export class PlayStateAutoStorage {
  constructor(prefix) {
    this.prefix = prefix;
  }

  #set(key, value) {
    localStorage.setItem(this.prefix + key, value);
  }
  #get(key, value) {
    const str = localStorage.getItem(this.prefix + key, value);
    return (!str ? null : JSON.parse(str));
  }

  #clear(key) {
    localStorage.removeItem(this.prefix + key);
  }

  clearElapsedTime(recordingId, filename) {
    this.#clear("latest");
    this.#clear(recordingId + filename);
  }

  updateElapsedTime(recordingId, filename, elapsed_seconds) {
    const recordingRecord = {
      elapsed: elapsed_seconds,
      recordingId: recordingId,
      filename: filename
    };
    const fileRecord = {
      elapsed: elapsed_seconds
    };

    const recordingStr = JSON.stringify(recordingRecord);
    const fileStr = JSON.stringify(fileRecord);
    this.#set("latest", recordingStr);
    this.#set(recordingId + filename, fileStr);
  }
  
  clearLatest() {
    this.#clear("latest");
  }
  getLatestElapsedTime() {
    const value = this.#get("latest");
    return value;
  }

  getRecordingElapsedTime(recordingId) {
    const value = this.#get(recordingId);
    return (!value ? 0 : value.elapsed);
  }

  getFileElapsedTime(recordingId, filename) {
    const value = this.#get(recordingId + filename);
    return (!value ? 0 : value.elapsed);
  }
}
