import react from 'react';
import { recordingPrecacheKey } from '../ManagedPrecacher';
import { S3LibraryContext } from '../S3LibraryContext';

export class RecordingUrlMapper {
  constructor(s3Library, precache) {
   this.s3Library = s3Library;
   this.precache = precache;
  }

  async recordingUrl(recording, signal = null) {
    const cacheUrlKey = (!this.precache) ? null:recordingPrecacheKey(recording.info);
    const existingCacheUrl = (!!cacheUrlKey && this.precache.has(cacheUrlKey)) ? cacheUrlKey:null;
    if(existingCacheUrl)
      return existingCacheUrl;
    else if(this.s3Library)
      return this.s3Library.getSongUrl(recording.info, signal);
    return;
  };

  async imageUrls(recording, signal = null) {
    if(!this.s3Library)
      return;
    return this.s3Library.getImageUrls(recording.info, signal);
  }
}

export const RecordingUrlMapperContext = react.createContext();

export function RecordingUrlMapperProvider({precache, children}) {
  const s3Library = react.useContext(S3LibraryContext);

  const mapper = react.useMemo(() => {
    return new RecordingUrlMapper(s3Library, precache);
  },[s3Library, precache]);
  return (
    <RecordingUrlMapperContext.Provider value={mapper} >
      {children}
    </RecordingUrlMapperContext.Provider>
  )
}