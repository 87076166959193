import react from 'react';
import { Box, Stack } from '@mui/material';
import {useTheme} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { enqueueSnackbar } from 'notistack';

export function LoadingNotice({show}) {

  return (
    <Box sx={{width:"fit-content", marginLeft:"auto", marginRight:"auto"}}>
    { show && <Box>[loading images]</Box>}
    </Box>
  )
}

export function ImageCarousel({ images }) {
  const theme = useTheme();
  const [index, setIndex] = react.useState(0);
  const [imageLoading, setImageLoading] = react.useState(false);
  react.useEffect(() => {
    setIndex(0);
    setImageLoading(!!images);
  }, [images]);


  const handleLeft = react.useCallback((event) => {
    if (images && index > 0)
      setIndex(index - 1);
    event.stopPropagation();
  }, [setIndex, index, images]);

  const handleRight = react.useCallback((event) => {
    if (images && index < images.length - 1)
      setIndex(index + 1);
    event.stopPropagation();
  }, [index, setIndex, images]);

  const prevDisabled = (index === 0) ? true : false;
  const nextDisabled = (!images || index >= images.length-1) ? true : false;
  function buildUrlSrcSet(isFirst, url, width) {
    let prefix = (isFirst) ? ", ":"";
    let widthStr = (!!width) ? ` ${width}w`:"";
    return `${prefix}${url}${widthStr}`;
  }
  function buildImageSrcSet(imagesObj) {
    let srcSet = "";
    if(imagesObj.url400)
      srcSet = buildUrlSrcSet(false, imagesObj.url400,400);
    if(imagesObj.url600)
      srcSet = srcSet + buildUrlSrcSet(srcSet.length > 0, imagesObj.url600, 600);
    return srcSet;
  }
  const handleImageLoaded = react.useCallback(() => {
    setImageLoading(false);
  },[setImageLoading]);

  const handleImgError = react.useCallback((event) => {
    console.log(event);
    enqueueSnackbar("Image failed to load.", {variant:"error"});
  })
  return (
    <react.Fragment>
      <Stack direction="row">
        <IconButton color={theme.palette.primary.main} disabled={prevDisabled} onClick={handleLeft}><KeyboardArrowLeft /></IconButton>
          <Box style={{ marginLeft: "auto", marginRight: "auto" }}>
            <LoadingNotice show={imageLoading} />
          { images && images.length > 0 &&
            <img onError={handleImgError} hidden={imageLoading} onLoad={handleImageLoaded} srcSet={buildImageSrcSet(images.at(index))} src={images.at(index).url} alt="source cassette" style={{ width: "100%", objectFit: "contain" }} />
          }
        </Box>
        <IconButton color={theme.palette.primary.main} disabled={nextDisabled} onClick={handleRight}><KeyboardArrowRight /></IconButton>
      </Stack>
    </react.Fragment>
  );
}
