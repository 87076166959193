export class ElapsedTimeUpdater {
  constructor(recordingId, filename, playStateStore, audioRef, priorTime = 0, timeStep = 30) {
    this.recordingId = recordingId;
    this.filename = filename;
    this.playStateStore = playStateStore;
    this.nextTime = priorTime + timeStep;
    this.audioRef = audioRef;
    this.timeStep = timeStep;
  }

  update() {
    if (!this.audioRef.current)
      return;
    const time = this.audioRef.current.currentTime;
    if (time > this.nextTime || (this.nextTime > time + this.timeStep)) {
      this.nextTime = time + this.timeStep;
      this.playStateStore.updateElapsedTime(this.recordingId, this.filename, time);
    }
  }
  setTrackFinished() {
    this.playStateStore.clearElapsedTime(this.recordingId, this.filename);
  }
}
