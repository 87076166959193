export function S3UnsignedUrlMapper(bucketEndpoint, prefix) {
  const lead = bucketEndpoint+prefix+"/";
  return (key) => {
    return lead + key;
  }
}

export class S3Presigner {
  constructor(presignerUrl, firebase_user) {
    this.presignerUrl = presignerUrl;
    this.user = firebase_user;
  }

  async getPresignedFileUrl(key, signal = null) {
    const accessToken = await this.user.getIdToken();
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      signal:signal
    };
    return fetch(`${this.presignerUrl}?` + new URLSearchParams({
      resource: key
    }), requestOptions)
      .then(response => response.json())
      .then(data => {
        return data.url;
      });
  }
}
