import react from 'react';
import { useEffect } from 'react';
import { ActiveSongUpdateContext } from './contexts/ActiveSongContext';
import { recoverLastPlayingSong, playStateStore } from './components/NowPlayingMicro';

export function LastPlayingRecovery({ library }) {
  const activeSongUpdate = react.useContext(ActiveSongUpdateContext);
  const [loadedOnce, setLoadedOnce] = react.useState(false);

  useEffect(() => {
    if(loadedOnce || !library || !activeSongUpdate)
      return;
    const lastPlayingSong = recoverLastPlayingSong(library, playStateStore);
    activeSongUpdate(lastPlayingSong);
    setLoadedOnce(true);
    console.log("loaded");
  }, [library, loadedOnce, setLoadedOnce, activeSongUpdate]);

  return;
}
