import react from 'react';
import { Box, List, ListItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import { ImageCarousel } from './ImageCarousel';
import { sortByFieldOrder, RECORDING_FIELDS } from '../LibrarySorting';
import { ActiveSongContext, ActiveSongUpdateContext } from '../contexts/ActiveSongContext';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import DownloadingIcon from '@mui/icons-material/Downloading';

import { RecordingPrecacheContext, RecordingPrecachedSetContext } from '../PrecacheLoader';


import { ConfirmationDialog } from './ConfirmationDialog';
import { RecordingUrlMapperContext } from '../contexts/RecordingUrlMapperProvider';

import { enqueueSnackbar } from 'notistack';

function LibraryItemImages({ recording, open }) {
  const recordingUrlMapper = react.useContext(RecordingUrlMapperContext);
  const [images, setImages] = react.useState(null);

  react.useEffect(() => {
    const controller = new AbortController();
    if (open && !images) {
      (async () => {
        if (!open || !recording)
          return;
        setImages([]);
        try {
          const images = await recordingUrlMapper.imageUrls(recording, controller.signal);
        if (images.length === 0)
          setImages(null);
        else
          setImages(images);
        } catch(err) {
          setImages(null);
          if(controller.signal.aborted)
            return;

          enqueueSnackbar("failed fetching image urls.", {variant:"error"});
          console.log("error fetching urls:" + err);
        }
      })()
    }
    return () => {controller.abort();};
  }, [open, recording, setImages]);
  return (
    <react.Fragment>
      {open && <ImageCarousel images={images} />}
    </react.Fragment>
  );
}


function LibraryDisplayItem({ item, onSongSelect, isSelected }) {
  const [open, setOpen] = react.useState(false);
  

  const clickTrap = react.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleClick = react.useCallback((event) => {
    setOpen(!open);
    event.stopPropagation();
  }, [open, setOpen]);

  const handlePlay = react.useCallback((event) => {
    onSongSelect(item);
    event.stopPropagation();
  }, [onSongSelect, item]);
  const theme = useTheme();
  const color = theme.palette.primary.dark;

  const sx = {
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.5em',
    boxShadow: `0 0 0.2em ${color} inset`
  };


  const smallTextSx = {
    opacity: 0.85,
    fontSize: "0.75em",
    letterSpacing: 0.2,
  };

  const textSx = {
    opacity: 1,
    fontWeight: 400,
    letterSpacing: 0.2,
    //color:blueGrey[100]
  };

  const mainClassName = isSelected ? 'LibraryItem selected' : 'LibraryItem';

  const boxSx = {
    display: "flex",
    flexDirection: "row",
    '& .PlayButton': {
      position: "relative",
      top: "0.0em"
    }
  };

  return (
    <ListItem className={mainClassName} sx={sx}>
      <Stack onClick={handleClick} direction="column" style={{ width: "100%" }}>
        <Stack direction="row">
          <Typography sx={smallTextSx} className="dateAdded">added: {item.info.dateAdded}</Typography>
          <Typography sx={smallTextSx} className="recordingId" style={{ marginLeft: "1em" }}>id: {item.info.recordingId}</Typography>
        </Stack>
        <Box sx={boxSx}>
          <div><IconButton disabled={!item.info.filename} className="PlayButton" size="small" onClick={handlePlay}><PlayCircleIcon /></IconButton></div>
          <Typography aria-label="title" className="title" sx={textSx}>{item.info.title}</Typography>
          <DownloadedStatus item={item} />
        </Box>
        <LibraryItemImages onClick={clickTrap} recording={item} open={open} />
      </Stack>
    </ListItem>
  );
}

export function DownloadedStatus({item}) {
  const precacheContext = react.useContext(RecordingPrecacheContext);
  const precachedSet = react.useContext(RecordingPrecachedSetContext);
  const [downloading, setDownloading] = react.useState({is:false});
  const [showDeleteConfirm, setShowDeleteConfirm] = react.useState(false);
  const [isCached, setIsCached] = react.useState(false);

  react.useEffect(() => {
    if(!item || !precachedSet)
      setIsCached(false);
    const isCached = precachedSet.has(item);
    setIsCached(isCached);
  },[item, precachedSet]);

  const handleDownload = react.useCallback((event) => {
    const controller = new AbortController();
    setDownloading({controller:controller, is:true});
    precacheContext.add(item, controller.signal)
      .catch((error) => {
        if(controller.signal.aborted) {
          console.log("download aborted");
          return;
        }
        enqueueSnackbar("media precaching failed", {variant:"error"});
        console.log("download aborted or failed: "+error);
      })
      .finally(() => {
        setDownloading({is:false});
      });
    event.stopPropagation();

  },[precacheContext, item, setDownloading]);

  const handleCancel = react.useCallback((event) => {
    event.stopPropagation();
    if(!downloading.is)
      return;
    downloading.controller.abort();
  },[downloading]);

  const handleDelete = react.useCallback((event) => {
    setShowDeleteConfirm(true);
    event.stopPropagation();
  },[setShowDeleteConfirm]);

  const confirmDelete = react.useCallback((event) => {
    precacheContext.delete(item);
    setShowDeleteConfirm(false);
    event.stopPropagation();
  },[precacheContext, item]);

  const noDelete = react.useCallback((event) => {
    setShowDeleteConfirm(false);
    event.stopPropagation();
  },[setShowDeleteConfirm])

  const canCache = precacheContext && precacheContext.canPrecache;
  const showDownloadButton = canCache && !downloading.is && !isCached;
  const showCancelButton = canCache && downloading.is;
  const showDeleteButton = canCache && !downloading.is && isCached;

  return (
  <react.Fragment>
      {showDownloadButton && <div><IconButton disabled={!item.info.filename} className="DownloadButton" size="small" onClick={handleDownload}><DownloadForOfflineIcon /></IconButton></div>}
      {showCancelButton &&
        <AnimatedDownloadingButton item={item} onCancel={handleCancel}/>
      }
      {showDeleteButton &&
      <div>
        <IconButton disabled={!item.info.filename} className="DeleteButton" size="small" onClick={handleDelete}>
          <FileDownloadDoneIcon/>
        </IconButton>
      </div>}
      <ConfirmationDialog 
        open={showDeleteConfirm}
        onClose={noDelete}
        onAccept={confirmDelete}
        description={`Remove "${item.info.title}" from local cache?`}
        title={"Remove Recording from Local Cache?"}
      />
  </react.Fragment>
  )
}

function AnimatedDownloadingButton({item, onCancel}) {
  const theme = useTheme();
  const animatedSx = react.useMemo(() => {
    return {
      '@keyframes colorChange':{
        "0%":{backgroundColor:theme.palette.background.paper},
        "30%":{backgroundColor:theme.palette.primary.main},
        "70%":{backgroundColor:theme.palette.primary.main},
        "100%":{backgroundColor:theme.palette.background.paper}
      },
      '.CancelButton':{
        animation:'colorChange 3s infinite'
      }
    }   
  },[theme]);

  return (
    <Box sx={animatedSx}>
    <IconButton 
      disabled={!item.info.filename} 
      className="CancelButton" 
      size="small"
      onClick={onCancel}>
      <DownloadingIcon />
    </IconButton>
    </Box>
  )
}

export function LibraryListing({ library }) {
  const activeSong = react.useContext(ActiveSongContext);
  const activeSongUpdate = react.useContext(ActiveSongUpdateContext);
  const theme = useTheme();
  const [recordings, setRecordings] = react.useState([]);

  react.useEffect(() => {
    (async () => {
      if (!library || !library.recordings) {
        setRecordings([]);
        return;
      }
      const sortFn = (r1, r2) => {
        return sortByFieldOrder(r1.info, r2.info, [RECORDING_FIELDS.dateAdded, RECORDING_FIELDS.recordingId]);
      };
      const sortedRecordings = library.recordings.slice().sort(sortFn).reverse();
      setRecordings(sortedRecordings);
    })();
  }, [library]);

  const onSongActivate = react.useCallback((item) => {
    activeSongUpdate(item);
  }, [activeSongUpdate]);

  const sx = react.useMemo(() => {
    const color = theme.palette.primary.dark;
    return {
      ".selected": {
        boxShadow: `0.5em 0 10px ${color} inset`
      }
    };
  }, [theme]);


  const items = recordings.map((item) => {
    return <LibraryDisplayItem isSelected={activeSong === item} onSongSelect={onSongActivate} item={item} key={item.info.recordingId} />;
  });

  return (
    <List className="LibraryListing" sx={sx}>
      {items}
    </List>
  );
}
