import { enqueueSnackbar } from 'notistack';
import react from 'react';
import { FirebaseSWOrchestration } from '../FirebaseSWOrchestration';

export const FirebaseAuthContext = react.createContext();
export const FirebaseUserContext = react.createContext();
export const FirebaseUIContext = react.createContext();
export const FirebaseAuthReadyContext = react.createContext();

export function FirebaseAuthProvider({children, canCache, firebase, auth, ui }) {
  const [user, setUser] = react.useState(null);
  const [waitingOnServiceWorker, setWaitingOnServiceWorker] = react.useState(canCache);

  react.useEffect(() => {
    if(navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("message", event => {
        if(event.data === FirebaseSWOrchestration.AUTH_COMPLETE_STR)
          setWaitingOnServiceWorker(false);
      });
    }
  },[setWaitingOnServiceWorker]);

  const updateUser = react.useCallback((firebase_user) => {
    console.log("user updated");
    if(firebase_user) {
      firebase_user.getIdTokenResult().then(idTokenResult => {
        if(idTokenResult.claims.readPublic)
          firebase_user.readPublicPermission = true;
        setUser(firebase_user);
        FirebaseSWOrchestration.prepareServiceWorkerForClient();
      }).catch(error => {
        console.log("error processing firebase user: "+error);
        enqueueSnackbar("Authentication Error. Reload App or re-login", {variant:"error"});
        setUser(null);
      })
    } else
      setUser(null);
  },[setUser]);

  react.useEffect(() => {
    if(!auth)
      return;
    auth.onAuthStateChanged(updateUser)
  },[auth, setUser, updateUser]);

  return (
    <FirebaseAuthContext.Provider value={auth}>
    <FirebaseUserContext.Provider value={user}>
    <FirebaseUIContext.Provider value={ui}>
    <FirebaseAuthReadyContext.Provider value={!waitingOnServiceWorker && user}>
      {children}
    </FirebaseAuthReadyContext.Provider>
    </FirebaseUIContext.Provider>
    </FirebaseUserContext.Provider>
    </FirebaseAuthContext.Provider>
  );
}
