import react from 'react';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';

/*
{
  "title":"Feb-9-1979 At Nellies - Hazel, Dale, Nancy, Randy, Lyle, Janet",
  "dateAdded":"2023-05-10",
  "recordingId":"EZCap-202303_10",
  "filename":"EZCap-202303_10_Feb-9-1979 At Nellies - Hazel, Dale, Nancy, Randy, Lyle, Janet.opus",
  "cassetteIds":[
    "EZCap-202303_10"
  ],
  "images":[
    "EZCap-202303_10_SideB_Feb-9-1979 At Nellies - Hazel, Dale, Nancy, Randy, Lyle, Janet.jpg",
    "EZCap-202303_10_SideA_Feb-9-1979 At Nellies - Hazel, Dale, Nancy, Randy, Lyle, Janet.jpg"
  ]
},*/

function recommendRecordingId(library ) {
  const datetime = new Date();
  const year = datetime.getFullYear();
  const iMonth = datetime.getMonth()+1;
  const month = (iMonth < 10) ? "0"+iMonth:iMonth;
  const date = (datetime.getDate() < 10) ? "0"+datetime.getDate():datetime.getDate();
  return `${year}${month}${date}`;
}

export default function CreateRecording({library, targetOnCancel}) {
  const [recordingId, setRecordingId] = react.useState("");
  
  const handleRecommend = react.useCallback(() => {
    if(!library)
      return;
    const recommendation = recommendRecordingId(library);
    setRecordingId(recommendation);

  },[library, setRecordingId])

  const handleCreate = react.useCallback(() => {
    console.log("STUB::handleCreate");
  })

  return (
    <form>
    <Button sx={{float:"right"}} onClick={handleRecommend}>Recommend ID</Button>
    <FormControl>
      <InputLabel htmlFor="recordingId">New Recording ID</InputLabel>
      <Input onChange={e => setRecordingId(e.target.value)} id="recordingId" aria-describedby='instructions' value={recordingId} />
      <FormHelperText id="instructions">Recording ID must be unique and should correspond to an individual tape, or complete session on part of a tape.</FormHelperText>
    </FormControl>
      <Button component={Link} to={targetOnCancel} sx={{marginLeft:"2em"}}>Cancel</Button>
      <Button onClick={handleCreate}  sx={{float:"right", marginRight:"2em"}}>Create</Button>
    </form>
  )

}

