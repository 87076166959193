/*"recordings":[
  {
          "title":"Feb-9-1979 At Nellies - Hazel, Dale, Nancy, Randy, Lyle, Janet",
          "dateAdded":"2023-05-10",
          "recordingId":"EZCap-202303_10",
          "filename":"EZCap-202303_10_Feb-9-1979 At Nellies - Hazel, Dale, Nancy, Randy, Lyle, Janet.opus",
          "cassetteIds":[
            "EZCap-202303_10"
          ],
          "images":[
            "EZCap-202303_10_SideB_Feb-9-1979 At Nellies - Hazel, Dale, Nancy, Randy, Lyle, Janet.jpg",
            "EZCap-202303_10_SideA_Feb-9-1979 At Nellies - Hazel, Dale, Nancy, Randy, Lyle, Janet.jpg"
          ]
  },*/
export function compareRecordingsByDateAdded(r1, r2) {
  const r1s = (r1.dateAdded) ? r1.dateAdded : "";
  const r2s = (r2.dateAdded) ? r2.dateAdded : "";
  return r1s.localeCompare(r2s);
}
export function compareRecordingsByTitle(r1, r2) {
  const r1s = (r1.title) ? r1.title : "";
  const r2s = (r2.title) ? r2.title : "";
  return r1s.localeCompare(r2s);
}
export function compareRecordingsById(r1, r2) {
  const r1s = (r1.recordingId) ? r1.recordingId : "";
  const r2s = (r2.recordingId) ? r2.recordingId : "";
  return r1s.localeCompare(r2s);
}
export class RECORDING_FIELDS {
  static title = "TITLE";
  static dateAdded = "DATE_ADDED";
  static recordingId = "RECORDING_ID";
}

export function sortByField(r1, r2, field) {
  switch (field) {
    case RECORDING_FIELDS.title: return compareRecordingsByTitle(r1, r2);
    case RECORDING_FIELDS.recordingId: return compareRecordingsById(r1, r2);
    case RECORDING_FIELDS.dateAdded: return compareRecordingsByDateAdded(r1, r2);
    default:
      throw new Error("Uknown Field Type: " + field);
  }
}
export function sortByFieldOrder(r1, r2, orderArray, lastVal = 0) {
  if (orderArray.length === 0 || lastVal !== 0)
    return lastVal;
  const next = orderArray.splice(0, 1);
  const val = sortByField(r1, r2, next[0]);
  return sortByFieldOrder(r1, r2, orderArray, val);
}
