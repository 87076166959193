import react from 'react';
import { useEffect } from 'react';
import { S3LibraryContext } from './S3LibraryContext';
import { Recording } from './Recording';
import { enqueueSnackbar } from 'notistack';

export function updateLibraryRecording(library, oldRecording, newRecording) {
  const newLibrary = {recordings:library.recordings.slice()};
  const index = newLibrary.recordings.indexOf(oldRecording);  
  newLibrary.recordings[index] = newRecording;
  return newLibrary;
}

function metdataToRecordings(recordingsMeta) {
  return recordingsMeta.map(item => {
    return new Recording(item);
  });
}

export function LibraryLoader({ library, precache, setLibrary, modifyGlobalSpinnerCount }) {
  const s3Library = react.useContext(S3LibraryContext);
  const [libraryMetadata, setLibraryMetadata] = react.useState(null);
  
  useEffect(() => {
    if(!libraryMetadata || !s3Library)
      setLibrary({recordings:[]});
    else {
      const recordings = metdataToRecordings(libraryMetadata.recordings);
      setLibrary({recordings:recordings});
    }
  },[s3Library, precache, setLibrary, libraryMetadata, modifyGlobalSpinnerCount]);

  useEffect(() => {
    if (!s3Library || !!libraryMetadata)
      return;
    modifyGlobalSpinnerCount(1);
    s3Library.getLibraryMetadata()
      .then(metadata => {
        setLibraryMetadata(metadata);
      }).catch(error => {
        enqueueSnackbar("Failed to load cassette library. Reload App to try again", {variant:"error"});
        alert("An error occurred loading the cassette library. Refresh page to try again." +error);
      }).finally(() => {
        modifyGlobalSpinnerCount(-1);
      });
}, [s3Library, modifyGlobalSpinnerCount, libraryMetadata, setLibraryMetadata]);

  return (null);
}
