import react from 'react';

import {useParams} from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export class RecordingId {
  static SUBSTRING_PATTERN= "[0-9]a-zA-Z._-]+";

  static fromStrEnd(str) {
    const lastElement = /(?<last>[0-9a-zA-Z._-]+)$/
    const matches = str.match(lastElement);
    if(!matches)
      return null;
    return matches.groups.last;
  }

  static isValid(str) {
    const validChars = /^[0-9a-zA-Z._-]+$/
    return !!str.match(validChars);
  }
}

export default function Recording({library}) {
  const {recordingId} = useParams();
  const [recording, setRecording] = react.useState(null);
  react.useEffect(() => {
    if(!library)
      setRecording(null);
    else {
      const found = library.recordings.find((item) => item.recordingId === recordingId);
      setRecording(found);
    }
      
  },[library]);

  return (
    <react.Fragment>
      {!recording && 
        <div>"Recording Not Found"</div>}
      {recording && <Box>
        <Typography>Title: </Typography>
        <Typography>Date Added: </Typography>
        <Typography>Recording ID: </Typography>
        <Typography>filename: </Typography>

        <div>STUB! A Recording Goes Here: {recordingId}, {recording.title} </div>
        </Box>
      }
    </react.Fragment>
  )
}

/*
  Displays all content available for a Recording. When user has edit privileges, let's them edit the values.


*/