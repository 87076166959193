import React from 'react';
import AccountStatus from '../contexts/AccountStatus.js';
import {
  Toolbar,
  AppBar,
  Menu,
  IconButton,
  FormControlLabel,
  Switch,
  FormControl,
  FormGroup,
  FormLabel,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useTheme} from '@mui/material/styles';
import { DarkModeConfigurationContext, DarkModeConfigurationUpdateContext } from '../contexts/DarkModeConfigurationProvider.js';
import { FirebaseAuthContext, FirebaseUIContext, FirebaseUserContext } from '../contexts/FirebaseUserContext.js';


export function DarkModeConfigMenu() {
  const configContext = React.useContext(DarkModeConfigurationContext);
  const updateContext = React.useContext(DarkModeConfigurationUpdateContext);

  const handleAutoChanged = React.useCallback((event) => {
    updateContext(configContext.modifyUseSystem(event.target.checked));
  },[configContext,updateContext]);

  const handleDarkChanged = React.useCallback((event) => {
    updateContext(configContext.modifyUseDark(event.target.checked));
  },[configContext, updateContext])

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Light/Dark Theme</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={configContext.useSystem} onChange={handleAutoChanged} name="useSystem" />
          }
          label="Use System Setting"
        />
        <FormControlLabel 
          control={
            <Switch 
            disabled={!!configContext.useSystem}
            checked={configContext.useDark} onChange={handleDarkChanged} name="useDark" />
          }
          label="Use Dark Theme"
        />
        
      </FormGroup>
    </FormControl>
  )
}

export function AppHeader() {
  const firebaseUser = React.useContext(FirebaseUserContext);
  const firebaseUi = React.useContext(FirebaseUIContext);
  const firebaseAuth = React.useContext(FirebaseAuthContext);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = !!anchorEl;

  const toggleOpen = React.useCallback((event) => {
    setAnchorEl((anchor) => !anchor ? event.currentTarget : null);
  }, [setAnchorEl]);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  },[setAnchorEl]);

  const handleSignout = React.useCallback(() => {
    firebaseAuth.signOut();
  },[firebaseAuth]);

  const sx = {
    '.MuiMenuItem-root:hover':{
      backgroundColor:theme.palette.primary.main,
    }
  }
  return (
    <AppBar className="AppHeader" position="sticky">
      <Toolbar>
        <AccountStatus onSignout={handleSignout} firebaseUi={firebaseUi} firebaseUser={firebaseUser}></AccountStatus>
        <IconButton
          sx={{marginLeft:"auto"}}
          id="overflow-button"
          aria-controls={open ? 'overflow-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true':undefined}
          onClick={toggleOpen}
          >
          <MenuIcon />

        </IconButton>
        <Menu
          sx={sx}
          id="overflow-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby':'overflow-button'
          }}
        >
        <DarkModeConfigMenu />
        </Menu>
      </Toolbar>
    </AppBar>

  );
}
