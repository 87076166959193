import react from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {DarkModeConfigurationContext} from './DarkModeConfigurationProvider';
export function AppThemeProvider({ children}) {
  const darkModeContext = react.useContext(DarkModeConfigurationContext);  
  const theme = react.useMemo(() => {
    const useDarkMode = darkModeContext.useDark;
    return createTheme({
      components: {
        MuiTypography: {
          defaultProps: {
            opacity: 1,
            fontWeight: 400,
            letterSpacing: 0.2
          }
        }
      },
      palette: {
        mode: useDarkMode ? 'dark' : 'light',
        background: {
          default: useDarkMode ? 'rgb(0,10,5)' : grey[200],
        },
        text: {
          primary: useDarkMode ? grey[300] : 'rgba(0,5,15,1)',
          secondary: useDarkMode ? alpha(grey[300], 0.7) : 'rgba(0,5,15,0.7)'
        }
      },
  })},[darkModeContext])
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}
