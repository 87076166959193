import './App.css';
import react from 'react';

import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { SnackbarProvider, closeSnackbar } from 'notistack';

import {
  BrowserRouter,
  Routes,
  Route,
  Outlet
} from "react-router-dom";

//MaterialUI Components
import {
  Box,
  Stack, 
  CircularProgress
} from '@mui/material';

import Root from "./routes/root";
import Recording from './routes/recording';
import { NowPlayingMicro } from './components/NowPlayingMicro';
import { LoginPage } from './routes/LoginPage';
import { ZeroTriggeredCounter } from './ZeroTriggeredCounter';
import { S3LibraryProvider } from './S3LibraryContext';
import CssBaseline from '@mui/material/CssBaseline';
import { ActiveSongProvider } from './contexts/ActiveSongContext';
import CreateRecording from './routes/CreateRecording';
import { AppHeader } from './components/AppHeader';
import { AppThemeProvider } from './contexts/AppThemeProvider';
import { DarkModeConfigurationProvider } from './contexts/DarkModeConfigurationProvider';
import { LibraryLoader } from './LibraryLoader';
import { FirebaseAuthProvider } from './contexts/FirebaseUserContext';
import { ServiceWorkerUserDispatcher } from './components/ServiceWorkerUserDispatcher';
import { PrecacheLoader, RecordingPrecacheProvider } from './PrecacheLoader';
import {RecordingUrlMapperProvider} from './contexts/RecordingUrlMapperProvider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const firebaseConfig = {

  apiKey: "AIzaSyClXXsOMfaejZpx5FRy1SCnNyIhB5gSLFs",

  authDomain: "us-placey-cassettes.firebaseapp.com",

  projectId: "us-placey-cassettes",

  storageBucket: "us-placey-cassettes.appspot.com",

  messagingSenderId: "98737668867",

  appId: "1:98737668867:web:586aa4d689c0be55785f36",

  measurementId: "G-KWWCT2K9G7"  
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const ui = new firebaseui.auth.AuthUI(auth);

export const uiConfig = {
  signInSuccessUrl:'/',
  signInOptions:[
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  tosUrl:'/tos',
  privacyPolicyUrl:'/privacypolicy'
};

export const LibraryContext = react.createContext();

function App() {  
  const [library, setLibrary] = react.useState(null);
  const [showGlobalSpinner, setShowGlobalSpinner] = react.useState(false);
  const [precache, setPrecache] = react.useState(null);
  const [globalSpinnerCounter, ] =  react.useState(new ZeroTriggeredCounter());

  const modifyGlobalSpinnerCount = react.useCallback((step) => {
    globalSpinnerCounter.modify(step
      , ()=>{setShowGlobalSpinner(true);}, ()=>{setShowGlobalSpinner(false);}
    );
  },[setShowGlobalSpinner]);

  // eslint-disable-next-line no-undef
  const canCache =( globalThis.caches && process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator);
  //const canCache = true;
  return (
    <react.StrictMode>
    <DarkModeConfigurationProvider>
      <AppThemeProvider>
        <CssBaseline/>
        <SnackbarProvider autoHideDuration={4000}
          action={(snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)}>
              <CloseIcon />
            </IconButton> 
          )}
        >
        <FirebaseAuthProvider 
          canCache={canCache}
          firebase={firebase}
          auth={auth}
          ui={ui}
          >
        <ServiceWorkerUserDispatcher />
        { canCache && <PrecacheLoader setPrecache={setPrecache} />}
        <S3LibraryProvider>
        <RecordingUrlMapperProvider precache={precache}>
        <RecordingPrecacheProvider library={library} precache={precache}>
        
        <LibraryLoader library={library} precache={precache} setLibrary={setLibrary} modifyGlobalSpinnerCount={modifyGlobalSpinnerCount}/>
        <ActiveSongProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/tos" element={<div>Terms of Service: This is a private server. If you're not invited family or friends, you don't belong here. If you belong here, use the service for personal use only, and don't do anything that may hurt the service or cause excessive consumption of resources. Use service at own risk. Beyond that, welcome!</div>} />
            <Route path="/privacypolicy" element={<div>Privacy Policy: This is a private, non-commercial service meant for family and friends. I make no legal guarantees or claims on matters of privacy regarding your usage of this service. </div>} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<Layout library={library} showGlobalSpinner={showGlobalSpinner} />}>
              <Route path="/createRecording" targetOnCancel="/" element={<CreateRecording library={library} />} />
              <Route index element={<Root library={library} />} />
              <Route path="/recording/:recordingId" element={<Recording library={library}/>} />
            </Route>
          </Routes>
        </BrowserRouter>
        </ActiveSongProvider>
        </RecordingPrecacheProvider>
        </RecordingUrlMapperProvider>
        </S3LibraryProvider>
        </FirebaseAuthProvider>
        </SnackbarProvider>
      </AppThemeProvider>
    </DarkModeConfigurationProvider>
    </react.StrictMode>
  )
}

function Layout({showGlobalSpinner, onSignout, library}) {

  return (
    <Stack className="AppLayout" direction="column" >
      {!!showGlobalSpinner && <CircularProgress style={{position:"absolute", left:"50%", top:"50%"}}/>}
      <AppHeader />
      <Box className="NowPlayingContainer">
        <NowPlayingMicro library={library} />
      </Box>
      <Box sx={{paddingTop:"1em"}}className="AppContent">
        <Outlet />
      </Box>
    </Stack>
    
  )
}
export default App;
