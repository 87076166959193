import react from 'react';


export const ActiveSongContext = react.createContext();
export const ActiveSongUpdateContext = react.createContext();

export function ActiveSongProvider ({children}) {
  const [activeSong, setActiveSong] = react.useState(null);
  
  return (
    <ActiveSongContext.Provider value={activeSong}>
      <ActiveSongUpdateContext.Provider value={setActiveSong}>
        {children}
      </ActiveSongUpdateContext.Provider>
    </ActiveSongContext.Provider>
  )
}