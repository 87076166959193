import react from 'react';
import { S3Presigner } from './S3Presigner';
import { S3Library } from './S3Library';
import {FirebaseAuthReadyContext, FirebaseUserContext} from './contexts/FirebaseUserContext';

const PRESIGN_URL = "https://us-central1-us-placey-cassettes.cloudfunctions.net/app/presign";

export const S3LibraryContext = react.createContext();

export function S3LibraryProvider ({children}) {
  const user = react.useContext(FirebaseUserContext);
  const authReady = react.useContext(FirebaseAuthReadyContext);
  const serviceWorkerAvailable = ( process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator);
  const [s3Library, setS3Library] = react.useState(null);
  const [isPreparingSW, setIsPreparingSW] = react.useState(true);

  react.useEffect(() => {
    if( serviceWorkerAvailable) {
      navigator.serviceWorker.ready.then((registration) => {
        setIsPreparingSW(false);
      });
    }
    else
      setIsPreparingSW(false);
      
  },[setIsPreparingSW])

  react.useEffect(() => {
    var newS3Library = null;
    if(isPreparingSW)
      return;
    const presigner = (user) ? new S3Presigner(PRESIGN_URL, user) : null;
    newS3Library = (presigner || serviceWorkerAvailable) ?
      new S3Library(presigner, serviceWorkerAvailable)
      : null;
    setS3Library(newS3Library);
  },[user, isPreparingSW, setS3Library]);

  return (
    <S3LibraryContext.Provider value={authReady && s3Library}>
      {children}
    </S3LibraryContext.Provider>
  )
}