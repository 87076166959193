import {Stack, Button} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {Link} from "react-router-dom";

function AccountStatus({firebaseUser, onSignout, fUpdateUser}) {
  const theme = useTheme();

  const buttonSx = {
    border:"0.1em",
    borderStyle:"ridge",
    backgroundColor:theme.palette.background.default
  }
  return (
    <Stack direction="row">
      <div id="firebaseui-auth-container"> </div>

      {!firebaseUser && <Link to="/login">
        <Button sx={buttonSx}>Login</Button></Link>}
      {!!firebaseUser && 
        <Button onClick={onSignout} sx={buttonSx}>Logout</Button>}
    </Stack>
  );
}

export default AccountStatus;