import react from 'react';
import './root.css';
import {Box} from '@mui/material';
import { LibraryListing } from '../components/LibraryListing';
import { FirebaseUserContext } from '../contexts/FirebaseUserContext';

function WelcomeScreen() {
  return (
    <react.Fragment>
      <h2>Welcome to the Family Audio Cassette Library</h2>
      <p>This site is invitation only, for family and friends. 
        If this describes you, please use the login/signup link 
        to register, then contact me personally to manually activate 
        your account. I will likely be adding new features and more 
        audio cassettes, as well as cleaning up the audio quality as 
        possible, so do expect changes in both the content and user
         experience.</p>
      <p>(note: If this site stops functioning, it may have hit a preconfigured daily usage cap for cost control; please check back again another day)</p>
    </react.Fragment>
  )
}

function WelcomeNonActiveUser({user}) {
  return (
    <react.Fragment>
      <h2>Welcome {user.displayName}!</h2>
      <p>You have successfully registered for this site 
        and are waiting on account activation.
      </p>
      <p>Next Step: Please contact me personally to activate your account.
        Provide me with the email address you used to sign up.
         New accounts may be automatically deleted if not activated 
         within 30 days.</p>
    </react.Fragment>
  )
}

export default function Root({library}) {
  const user = react.useContext(FirebaseUserContext);

  return (
    (<Box >
      {!user && <WelcomeScreen /> }
      {!!user && !user.readPublicPermission && <WelcomeNonActiveUser user={user}/> }
      {!!user && !!user.readPublicPermission && library && 
          <LibraryListing library={library}/>
      }
      </Box>)
  )
}
