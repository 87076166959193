import react from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import DarkModeConfig from '../DarkModeConfig';
import { enqueueSnackbar } from 'notistack';

const LOCAL_STORAGE_DARKMODE_CONFIG_KEY = "DEVICE_DARKMODE_CONFIG";

function loadJsonFromLocalStorage(key) {
  if(!localStorage) 
    return null;
  try {
    const item = localStorage.getItem(key);
    if(!item)
      return null;
    const obj = JSON.parse(item);
    return obj;
  } catch(e) {
    console.log(e);
  }
  return null;
}

function storeJsonToLocalStorage(key, obj) {
  if(!localStorage)
    return false;
  const str = JSON.stringify(obj);
  localStorage.setItem(key, str);
}

function initializeDarkModeConfig() {
  return {useSystem:true, useDark:false};
}

function loadDarkModeConfig(prefersDarkMode) {
  //load from local storage
  let config = loadJsonFromLocalStorage(LOCAL_STORAGE_DARKMODE_CONFIG_KEY);
  if(!config)
    config = initializeDarkModeConfig();
  return new DarkModeConfig(config.useSystem, config.useDark, prefersDarkMode);
}

function saveDarkModeConfig(config) {
  storeJsonToLocalStorage(LOCAL_STORAGE_DARKMODE_CONFIG_KEY, config.config);
}

export const DarkModeConfigurationContext = react.createContext();
export const DarkModeConfigurationUpdateContext = react.createContext();

export function DarkModeConfigurationProvider({children}) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [config, setConfig] = react.useState(loadDarkModeConfig(prefersDarkMode));

  react.useEffect(() => {
    try {
      saveDarkModeConfig(config);
    } catch(error) {
      console.log("failed to save dark mode config: "+error);
      enqueueSnackbar("failed to save darkmode config", {variant:"error"});
    }
  },[config]);
  return (
    <DarkModeConfigurationContext.Provider value={config}>
      <DarkModeConfigurationUpdateContext.Provider value={setConfig}>
        {children}
      </DarkModeConfigurationUpdateContext.Provider>
    </DarkModeConfigurationContext.Provider>
  )
}
