export default class DarkModeConfig {
  constructor(useSystem, useDark, prefersDarkMode) {
    this.configObj = {useSystem:useSystem};
    this.configObj.useDark = (useSystem) ? prefersDarkMode : useDark;
    this.prefersDarkMode = prefersDarkMode;
    Object.freeze(this.configObj);
  }
  
  modifyUseSystem(useSystem) {
    if(this.configObj.useSystem === useSystem)
      return this;
    const newDark = (useSystem) ? this.prefersDark : this.configObj.useDark;
    return new DarkModeConfig(useSystem, newDark, this.prefersDarkMode)
  }

  modifyUseDark(useDark) {
    if(this.configObj.useSystem)
      return this;
    if(this.configObj.useDark === useDark)
      return this;
    return new DarkModeConfig(this.configObj.useSystem, useDark, this.prefersDarkMode);
  }

  modifyPrefersDarkMode(preference) {
    return new DarkModeConfig(this.configObj.useSystem, this.configObj.useDark, preference);
  }

  get useDark() { return this.configObj.useDark;}
  get useSystem() { return this.configObj.useSystem;}
  get config() { return this.configObj;}

}
