export const DEFAULT_PRECACHE_PREFIX="/cassettes_precache";
export const DEFAULT_PRECACHE_CACHE_NAME="MANAGED_PRECACHER"
export class PrecacheError extends Error {}

export function recordingPrecacheKey(recordingMeta, prefix = DEFAULT_PRECACHE_PREFIX) {
  if(!recordingMeta.filename)
    throw new PrecacheError("Invalid Recording Filename");
  if(!recordingMeta.recordingId)
    throw new PrecacheError("Invalid Recording ID");
  return `${prefix}/${recordingMeta.recordingId}/${recordingMeta.filename}`;
}

export async function precache(openedCache, key, url, signal, update_fn = null) {
  if(update_fn)
    console.log("ManagedPrecacher::precache: update callbacks not yet implemented. No updates will be acknowledged.")
  return fetch(new Request(url, {signal:signal})).then((response) => {
    return openedCache.put(key, response);
  });
}

export async function getCachedPathnamesAsSet(cache) {
  const keys = await cache.keys();
  return new Set(keys.map((request) => {
    const url = new URL(request.url);
    const decoded = decodeURI(url.pathname);
    return decoded;
  }));
}

export class ManagedPrecache {
  constructor(openedCache, cachedKeys) {
    this.cache = openedCache;
    this.cachedKeys = cachedKeys;
  }

   /**
   * 
   * @param {*} key 
   * @param {*} url 
   * @param {*} update_fn 
   */
  async add(key, url, signal, update_fn) {
    await precache(this.cache, key, url, signal, update_fn);
    this.cachedKeys.add(key);
  }

  has(key) {
    return this.cachedKeys.has(key);
  }

  delete(key) {
    this.cachedKeys.delete(key);
    return this.cache.delete(key);

  }

  static async build(cacheName=DEFAULT_PRECACHE_CACHE_NAME) {
    const cache = await caches.open(cacheName);
    const keys = await getCachedPathnamesAsSet(cache);
    return new ManagedPrecache(cache, keys);
  }
}