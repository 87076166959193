import { useEffect, useContext, useState, createContext, useMemo } from 'react';
import { ManagedPrecache, recordingPrecacheKey } from './ManagedPrecacher';
import { RecordingUrlMapperContext } from './contexts/RecordingUrlMapperProvider';
import { enqueueSnackbar } from 'notistack';

export function PrecacheLoader({ setPrecache }) {
  useEffect(() => {(async() => {
    try {
      const precache = await ManagedPrecache.build();
      setPrecache(precache);
    } catch(error) {
      console.log("failed to load precache");
      enqueueSnackbar("failed to load media precache. Continuing without precache", {variant:"info"});
    }
  })()
  }, [setPrecache]);
}
export const RecordingPrecachedSetContext = createContext();

export const RecordingPrecacheContext = createContext();

export class RecordingPrecache {
  constructor(precache, recordingUrlMapper) {
    this._precache = precache;
    this._recordingUrlMapper = recordingUrlMapper;
  }
  async add(recording, signal, updateFn) {
    if(this.has(recording))
      return;
    const key = recordingPrecacheKey(recording.info);
    const url = await this._recordingUrlMapper.recordingUrl(recording, signal);
    await this._precache.add(key, url, signal, updateFn);
  }
  delete(recording) {
    const key = recordingPrecacheKey(recording.info);
    this._precache.delete(key);
  }  
}
export function RecordingPrecacheProvider({library, precache, children}) {
  const [precachedSet, setPrecachedSet] = useState(new Set());
  const recordingUrlMapper = useContext(RecordingUrlMapperContext);
  useEffect(() => {
    if(!library || !precache) {
      setPrecachedSet(new Set());
      return;
    }
    const precachedRecordings = library.recordings.filter((recording) => {
      const key = recordingPrecacheKey(recording.info);
      return precache.has(key);
    });
    setPrecachedSet(new Set(precachedRecordings));
  },[library, precache])

  const value = useMemo(() => {
    if(!precache)
      return {canPrecache:false, add:async ()=>{}, delete:async ()=>{}, has:()=>{}}
    return {
      canPrecache:true, 
      add:async (recording, signal, updateFn)=>{
        const key = recordingPrecacheKey(recording.info);
        if(precache.has(key))
          return;
        const url = await recordingUrlMapper.recordingUrl(recording, signal);
        await precache.add(key, url, signal, updateFn);
        setPrecachedSet(oldSet => {
          const newSet = new Set(oldSet);
          newSet.add(recording);
          return newSet;
        });
      },
      delete:async (recording)=>{
        const key = recordingPrecacheKey(recording.info);
        precache.delete(key);
        setPrecachedSet(oldSet => {
          const newSet = new Set(oldSet);
          newSet.delete(recording);
          return newSet;
        });
      }
    }
  },[precache, recordingUrlMapper, setPrecachedSet]);

  return (
  <RecordingPrecacheContext.Provider value={value}>
  <RecordingPrecachedSetContext.Provider value={precachedSet} >
    {children}
  </RecordingPrecachedSetContext.Provider>
  </RecordingPrecacheContext.Provider>
  )

}