import react from 'react';
import { FirebaseUserContext } from '../contexts/FirebaseUserContext';

function setServiceWorkerUser(firebaseUser) {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.active.postMessage({ firebaseUser: "TEST", type: "UPDATE_USER" });
      console.info("updating service worker with firebase user");
    });
  }
}

export function ServiceWorkerUserDispatcher() {
  const user = react.useContext(FirebaseUserContext);
  react.useEffect(() => {
    setServiceWorkerUser(user);
  }, [user]);
}
