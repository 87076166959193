export class FirebaseSWOrchestration {
  static AUTH_COMPLETE_STR = "FIREBASE_AUTH_COMPLETE";
  static PREPARE_CLIENT_STR = "PREPARE_CLIENT";

  static async notifyClientAuthComplete(client) {
    client.postMessage(this.AUTH_COMPLETE_STR);
  }

  static async notifyAllClientsAuthComplete() {
    // eslint-disable-next-line no-undef
    const activeClients = await globalThis.clients.matchAll();
    for (const client of activeClients) {
      this.notifyClientAuthComplete(client);
    }
  }

  static prepareServiceWorkerForClient() {
    if(navigator.serviceWorker) {
      navigator.serviceWorker.ready.then(registration => {
        registration.active.postMessage(
          {type:FirebaseSWOrchestration.PREPARE_CLIENT_STR}
        );
      });
    }
  }
}
