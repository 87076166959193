import react from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { ElapsedTimeUpdater } from '../ElapsedTimeUpdater';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Replay5Icon from '@mui/icons-material/Replay5';
import Replay30Icon from '@mui/icons-material/Replay30';
import Forward5Icon from '@mui/icons-material/Forward5';
import Forward30Icon from '@mui/icons-material/Forward30';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CloseIcon from '@mui/icons-material/Close';
import { PlayStateAutoStorage } from '../PlayStateAutoStorage';
import { ActiveSongContext,ActiveSongUpdateContext } from '../contexts/ActiveSongContext';
import { LastPlayingRecovery } from '../LastPlayingRecovery';
import { FirebaseUserContext } from '../contexts/FirebaseUserContext';
import { RecordingUrlMapperContext } from '../contexts/RecordingUrlMapperProvider';

import {enqueueSnackbar } from 'notistack';

function PlayControlButtons({isPlaying, onAdjustElapsedTime, onPause, onPlay}) { 
  const iconSx={
  //  color:blueGrey[50]
  }
  const boxSx = {
    display:"flex",
    flexDirection:"row",
  }

  const handleBack30 = react.useCallback(() => {
    onAdjustElapsedTime(-30);
  }, [onAdjustElapsedTime]);

  const handleBack5 = react.useCallback(() => {
    onAdjustElapsedTime(-5);
  }, [onAdjustElapsedTime]);

  const handleForward5 = react.useCallback(() => {
    onAdjustElapsedTime(5);
  }, [onAdjustElapsedTime]);

  const handleForward30 = react.useCallback(() => {
    onAdjustElapsedTime(30);
  }, [onAdjustElapsedTime]);

  return (
    <Box sx={boxSx}>
      <Box sx={{marginLeft:"auto", marginRight:"auto"}}>
      <IconButton onClick={handleBack30}><Replay30Icon fontSize="large" sx={iconSx}/></IconButton>
      <IconButton onClick={handleBack5}><Replay5Icon fontSize="large" sx={iconSx}/></IconButton>
      {isPlaying && <IconButton onClick={onPause} fontSize="large" sx={iconSx}><PauseIcon></PauseIcon></IconButton>}
      {!isPlaying && <IconButton  onClick={onPlay} fontSize="large" sx={iconSx}><PlayArrowIcon/></IconButton>}
      <IconButton onClick={handleForward5}><Forward5Icon fontSize="large" sx={iconSx}/></IconButton>
      <IconButton onClick={handleForward30}><Forward30Icon fontSize="large" sx={iconSx}/></IconButton>
      </Box>
    </Box>
  )
}

function formatDuration(value) {
  const minute = Math.floor(value/60);
  const seconds = value-(minute*60);
  const secondsStr = seconds < 10 ? `0${seconds}`:`${seconds}`;
  return `${minute}:${secondsStr}`
}

function TimeControlSlider(
  {isPlaying, duration, position, onAdjustElapsedTime, onPause, onPlay}
  ) {
  const [activePosition, setActivePosition] = react.useState(0);

  const stackSx = {
    margin:"0 1em 0 1em",
    gap:'0.75em',
    alignItems:'center'
  }

  const timeSx = {
    fontSize: '0.75rem',
    opacity: 0.70,
    fontWeight: 500,
    letterSpacing: 0.2,
    //color:blueGrey[100]
  }

  react.useEffect(() => {
    setActivePosition(position);
  },[position, setActivePosition]);

  const handleChange = react.useCallback((_, value) => {
    if(isPlaying) {
      onPause();
    }
    setActivePosition(value);
  }, [setActivePosition, onPlay, isPlaying] )

  const handleChangeCommitted = react.useCallback((_, value) => {
    onAdjustElapsedTime(value-position);
    if(!isPlaying)
      onPlay();
  }, [position, onAdjustElapsedTime, ])
  
  return (
    <Stack direction="row" sx={stackSx}>
              <Typography sx={timeSx} >{formatDuration(position)}</Typography>
      <Slider 
        aria-label="time-indicator"
        size="small"
        value={activePosition}
        min={0}
        step={5}
        max={duration}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
      />
    
        <Typography sx={timeSx} >{formatDuration(duration-position)}</Typography>

    </Stack>
  )
}

export const playStateStore = new PlayStateAutoStorage("playtimes_");

export function recoverLastPlayingSong(library, playStateAutoStorage) {
  if(!library)
    return null;
  const last = playStateAutoStorage.getLatestElapsedTime();
  if(!last)
    return null;
  const lastSong = library.recordings.find(item => {
    return item.info.recordingId === last.recordingId;
  });
  if(!lastSong)
    return null;
  return lastSong;
}

export function NowPlayingMicro({library}) {
  const theme = useTheme();
  const recordingUrlMapper = react.useContext(RecordingUrlMapperContext);
  const user = react.useContext(FirebaseUserContext);
  const activeSong = react.useContext(ActiveSongContext);
  const activeSongUpdate = react.useContext(ActiveSongUpdateContext);
  const audioRef = react.useRef();
  const [duration, setDuration] = react.useState(0);
  const [position, setPosition] = react.useState(0);
  const [isPlaying, setIsPlaying] = react.useState(false);
  
  useEffect(() => {
    let newUpdater = null;
    const abortController = new AbortController();
    if(!!activeSong && !!audioRef.current && !!recordingUrlMapper) {
      let song = activeSong.info;
      const priorTime = playStateStore.getFileElapsedTime(song.recordingId, song.filename);
      newUpdater = new ElapsedTimeUpdater(song.recordingId, song.filename, playStateStore, audioRef, priorTime, 30);
      audioRef.current.ontimeupdate = null;
      audioRef.current.src = "";
      audioRef.current.onloadedmetadata = (event) =>{ 
        audioRef.current.onloadedmetadata = null;
        audioRef.current.currentTime = priorTime;
        audioRef.current.ontimeupdate = () => { newUpdater.update(); };
        audioRef.current.onended = () => { newUpdater.setTrackFinished(); };
        console.log("onplay");
      }
      recordingUrlMapper.recordingUrl(activeSong, abortController.signal).then((url) => {
        console.log("setting new media url: "+url);
        audioRef.current.src = url;
      }).catch((error) => {
        if(abortController.signal.aborted)
          return;
        enqueueSnackbar("failed to get media url. Check connection and try again", {variant:"error"});
        console.log("failed to get media url: "+error);
      });
      return () => {abortController.abort();}
    }
  }, [recordingUrlMapper, activeSong, audioRef]);

  const handleTimeUpdate = react.useCallback(() => {
    setPosition(Math.floor(audioRef.current.currentTime));
  },[setPosition]);

  const handleDurationChanged = react.useCallback(() => {
    console.log("durationChanged: "+audioRef.current.duration);
    setDuration(Math.floor(audioRef.current.duration));
  },[setDuration]);

  const handlePlay = react.useCallback(() => {
    setIsPlaying(true);
  },[setIsPlaying])

  const handlePause = react.useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  const doPause = react.useCallback(() => {
    if(!!audioRef.current)
      audioRef.current.pause();
  }, [audioRef])
  const doPlay = react.useCallback(() => {
    if(!!audioRef.current)
      audioRef.current.play();
  });

  const handleAdjustTime = react.useCallback((offset) => {
    if(!audioRef.current)
      return;
    var val = Math.max(0, position+offset);
    val = Math.min(val, duration);
    audioRef.current.currentTime = val;
  });

  const handleClose = react.useCallback(() => {
    doPause();
    activeSongUpdate(null);
    playStateStore.clearLatest();
  },[activeSongUpdate]);

  const cardSx = {
    backgroundColor: theme.palette.background.default,
    border:"0.10em",
    borderStyle:"ridge",
    padding:"0.5em",
    borderRadius:"0 0 1.5em 1.5em"
  }

  const smallTextSx = {
    fontSize:'0.7em',
    margin: "auto 0.2em auto 0",
  }
  const textSx = {
    overflow:"hidden",
    whiteSpace:"nowrap",
    textOverflow:"ellipsis"
  }

  return (
    <react.Fragment>
      <LastPlayingRecovery library={library} />
      {!!user && !!activeSong && (
        <Card className="NowPlayingMicro" sx={cardSx}>
          <Stack direction="row">
           <Typography sx={smallTextSx}>Playing:</Typography>
           <Typography sx={textSx}>{activeSong.info.title}</Typography>
           <IconButton sx={{marginLeft:"auto"}} onClick={handleClose}><CloseIcon /></IconButton>
          </Stack>
          {!!audioRef.current && 
          (<react.Fragment>
          <Box >
            <PlayControlButtons onAdjustElapsedTime={handleAdjustTime} onPause={doPause} onPlay={doPlay} isPlaying={isPlaying} />
            <TimeControlSlider
              isPlaying={isPlaying}
              position={position}
              onAdjustElapsedTime={handleAdjustTime} 
              onPause={doPause}
              onPlay={doPlay}
              duration={duration}
            />
          </Box>
          </react.Fragment>)}
          <audio 
            onTimeUpdate={handleTimeUpdate} 
            onDurationChange={handleDurationChanged}
            autoPlay 
            onPause={handlePause}
            onPlay={handlePlay}
            ref={audioRef}
            controls={false}
            crossOrigin='anonymous'
          />
        </Card>
      )}
    </react.Fragment>
  );
}
