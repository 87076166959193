import react from 'react';
import { Dialog, DialogActions } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CheckIcon from '@mui/icons-material/Check';
import Zoom from '@mui/material/Zoom';

const ConfirmationTransition = react.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} style={{ transitionDelay: '0ms' }} {...props} />;
});

export function ConfirmationDialog({ open, onClose, onAccept, description, title }) {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='confirm-title'
      aria-describedby='confirm-description'
      TransitionComponent={ConfirmationTransition}
    >
      <DialogTitle id="confirm-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={onAccept} sx={{ marginRight: "auto" }}><CheckIcon /></IconButton>
        <IconButton onClick={onClose}><CancelIcon /></IconButton>
      </DialogActions>
    </Dialog>
  );
}
