import react, { useEffect } from 'react';
import { uiConfig } from '../App';
import { FirebaseUIContext, FirebaseUserContext } from '../contexts/FirebaseUserContext';

export function LoginPage() {
  const user = react.useContext(FirebaseUserContext)
  const firebaseUi = react.useContext(FirebaseUIContext);

  useEffect(() => {
    if(!firebaseUi)
      return;
    if (user !== null)
      firebaseUi.reset();

    else
      firebaseUi.start('#firebaseui-auth-container', uiConfig);
    /*if(ui.isPendingRedirect()) {
          console.log("startin ui");
          ui.start('#firebaseui-auth-container', uiConfig);
    }*/
  }, [firebaseUi, user]);

  return (
    <div id="firebaseui-auth-container"> </div>
  );
}
