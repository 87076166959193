//import { recordingPrecacheKey } from './ManagedPrecacher';

export type RecordingJson = {
  title:string,
  recordingId:string,
  recordingYear:string | number,
  filename:string,
  dateAdded:string
}
export class Recording {
  info: RecordingJson;
  constructor(songInfo: RecordingJson) {
    this.info = songInfo;
  }

  /*static build(recordingMeta) {
    const cacheUrl = (!precache) ? null : recordingPrecacheKey(recordingMeta);
    const existingCacheUrl = (!!cacheUrl && precache.has(cacheUrl)) ? cacheUrl : null;
    return new Recording(s3Library, recordingMeta, existingCacheUrl);
  }*/
  /*static update(recording, s3Library, precache) {
    return Recording.build(s3Library, recording.info, precache);
  }*/
}
