export class ZeroTriggeredCounter {
  constructor() {
    this.count = 0;
  }

  modify(step, fromZeroFn, toZeroFn) {
    console.log("modify zero counter: "+step);
    if (step === 0)
      return;
    var runFunc = (this.count === 0) ? fromZeroFn : null;
    this.count += step;
    runFunc = (this.count === 0) ? toZeroFn: runFunc;
    if(!!runFunc)
      runFunc();
  }
}
